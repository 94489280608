<template>
    <div>
        <div class="header_wrap position-relative">
            <Header class="header flex-between-center p-3 py-2 position-fixed top-0 w-100" style="background-color: #ffc107;">
                <div class="fs-6">
                    <span class="text-yellow-1 text-opacity-50" @click="goBack()">
                        <i class="fas fa-chevron-left text-burgundy-2 h3 fw-bold ps-2 pointer"></i>
                    </span>
                </div>
                <div class="tit">
                    <span class="d-block text-yellow-1 fs-5 fw-bold text-truncate"></span>
                </div>
                <div>

                </div>
                
            </Header>
        </div>
        <img src="@/assets/img/howtobuysell.jpg"  class="img-fluid w-100" alt="">
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'

export default {
    components:{
        SubHeader
    },
    data(){
        return{
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        } 
    }
}
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
<style lang="scss" scoped>
    .menu .badge {
        top: -2px;
        right: -8px;
    }

    .header_wrap {
        padding-bottom: 55px;
        z-index: 100;
        .header {
            max-width: 768px; //반응형

            height: 55px;
            background-color: #36001f;

            .tit{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 70%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
            }
        }
    }
</style>